import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import type { CurrentPostContextObject } from "@circle-react/contexts";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { isPublicCommunity } from "@circle-react/helpers/communityHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import * as postHelpers from "@circle-react/helpers/postHelpers";
import {
  isPublicSpace,
  isSpaceMember,
} from "@circle-react/helpers/spaceHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import type { Post } from "@circle-react/types/Post";

interface Options {
  refetch: false;
}

export interface UseCurrentPostHelpersProps {
  onChangeSharePost?: (post: Post, options: Options) => void;
}

interface Params {
  postSlug: string;
}

type MutationError = unknown;

interface MutationVariables {
  postId: string;
  body: any;
}

type PostContextObject = Omit<CurrentPostContextObject, "record">;

export interface UseCurrentPostHelpers extends PostContextObject {
  canDestroyPost: boolean;
  canDuplicatePost: boolean;
  canManagePost: boolean;
  canReportPost: boolean;
  canUpdatePost: boolean;
  getSharePostLink: (...args: any[]) => any;
  isBasicPost: boolean;
  isEventPost: boolean;
  isImagePost: boolean;
  isLoadingSharePostData: boolean;
  isPostBodyEmpty: boolean;
  isPostMetaHidden: boolean;
  isSharedPostView: boolean;
  isSharedPostViewWithMinimalLayout: boolean;
  isSharedPostWithTeaserLayout: boolean;
  isTiptapPost: boolean;
  isTrixPost: boolean;
  post?: any;
  posterJoinedDate: string;
  sharePostData?: any;
  shouldHideCommentsListForSpace: (arg: any) => boolean;
  shouldShowLeaveCommentButton: boolean;
  showEngagementActions: boolean;
  showGatedCommentSection: boolean;
  showGatedCommentSectionAtBottom: boolean;
  showGatedCommentSectionAtTop: boolean;
  showModerationInActionsDropdown: boolean;
  showPostActions: boolean;
  showShareOnWebOptions: (space: any) => boolean;
  showSpaceName: boolean;
}

export const useCurrentPostHelpers = ({
  onChangeSharePost,
}: UseCurrentPostHelpersProps = {}): UseCurrentPostHelpers => {
  const { postSlug } = useParams<Params>();
  const isIframeEmbed = useIsIframeEmbed();
  const { record: post, ...rest } = useCurrentPostContext();

  const posterJoinedDate = formatDateString(
    post?.community_member?.profile_confirmed_at,
    {
      month: "long",
      year: "numeric",
      day: "numeric",
    },
  );

  const { currentUser, currentCommunity, currentCommunityMember } =
    usePunditUserContext();

  const isSharedPostView = postHelpers.isSharedPostView(post, {
    postSlugInUrl: postSlug,
  });

  const isSharedPostViewWithMinimalLayout = Boolean(
    isSharedPostView && post.minimal_layout,
  );

  const isSharedPostWithTeaserLayout = Boolean(
    isSharedPostView && post.show_post_teaser,
  );

  const shouldShowPostActions =
    !isIframeEmbed &&
    postHelpers.showPostActions(post, {
      currentUser,
      postSlugInUrl: postSlug,
    });

  const canManagePost = postHelpers.canManagePost(post);
  const canUpdatePost = postHelpers.canUpdatePost(post);
  const canDuplicatePost = postHelpers.canDuplicatePost(post);
  const canDestroyPost = postHelpers.canDestroyPost(post);
  const canReportPost = postHelpers.canReportPost(post);

  const showShareOnWebOptions = (space: any) =>
    !isSharedPostView &&
    !isIframeEmbed &&
    postHelpers.canSharePost(post, currentCommunity, space);

  const shouldShowSpaceName = isSharedPostViewWithMinimalLayout;

  const shouldShowGatedCommentSection = Boolean(
    (!currentUser && post.is_comments_enabled && !post.is_comments_closed) ||
      isSharedPostWithTeaserLayout,
  );

  const shouldShowGatedCommentSectionAtTop =
    shouldShowGatedCommentSection && post.comments_count > 5;

  const shouldShowGatedCommentSectionAtBottom =
    shouldShowGatedCommentSection && post.comments_count <= 5;

  const shouldShowModerationInActionsDropdown = Boolean(
    post.flagged_for_approval_at && canManagePost,
  );

  const isBasicPost = postHelpers.isBasicPost(post);
  const isEventPost = postHelpers.isEventPost(post);
  const isImagePost = postHelpers.isImagePost(post);
  const isTiptapPost = postHelpers.isUsingTiptapEditor(post);
  const isTrixPost = postHelpers.isUsingTrixEditor(post);
  const isPostBodyEmpty = postHelpers.isPostBodyEmpty(post);
  const isPostMetaHidden = postHelpers.isPostMetaHidden(post);

  const shouldShowEngagementActions = Boolean(
    !isSharedPostViewWithMinimalLayout &&
      (post.is_liking_enabled || post.is_comments_enabled),
  );

  const shouldShowLeaveCommentButton = Boolean(
    isSharedPostViewWithMinimalLayout && currentCommunityMember,
  );

  const shouldHideCommentsListForSpace = ({ space }: any) => {
    const canAccessCommunity =
      isPublicCommunity(currentCommunity) || currentCommunityMember;
    const canAccessSpace =
      isPublicSpace(space) ||
      isSpaceMember(space) ||
      isCommunityAdmin(currentCommunityMember);
    const canAccessPost = canAccessCommunity && canAccessSpace;

    return (
      !canAccessPost ||
      isSharedPostViewWithMinimalLayout ||
      isSharedPostWithTeaserLayout
    );
  };

  const {
    mutate: getSharePostLink,
    data: sharePostData,
    isLoading: isLoadingSharePostData,
  } = useMutation<Post, MutationError, MutationVariables>(
    ({ postId, body }) => reactQueryPut(`/posts/${postId}/share`, body),
    {
      onSuccess: data => {
        if (onChangeSharePost) {
          onChangeSharePost(
            {
              ...post,
              ...data,
            },
            { refetch: false },
          );
        }
      },
    },
  );

  return {
    post,
    ...rest,
    canDestroyPost,
    canDuplicatePost,
    canManagePost,
    canReportPost,
    canUpdatePost,
    getSharePostLink,
    isBasicPost,
    isEventPost,
    isImagePost,
    isLoadingSharePostData,
    isPostBodyEmpty,
    isPostMetaHidden,
    isSharedPostView,
    isSharedPostViewWithMinimalLayout,
    isSharedPostWithTeaserLayout,
    isTiptapPost,
    isTrixPost,
    posterJoinedDate,
    sharePostData,
    shouldHideCommentsListForSpace,
    shouldShowLeaveCommentButton,
    showEngagementActions: shouldShowEngagementActions,
    showGatedCommentSection: shouldShowGatedCommentSection,
    showGatedCommentSectionAtBottom: shouldShowGatedCommentSectionAtBottom,
    showGatedCommentSectionAtTop: shouldShowGatedCommentSectionAtTop,
    showModerationInActionsDropdown: shouldShowModerationInActionsDropdown,
    showPostActions: shouldShowPostActions,
    showShareOnWebOptions,
    showSpaceName: shouldShowSpaceName,
  };
};
